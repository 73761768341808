.buttonWrapper {
  margin-top: 2rem;
}

.button {
  height: 100%;
}

.toggleRow {
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
}

.legalWrapper {
  max-height: 20rem;
  overflow-y: scroll;
}
